export class ErrorMessageLocalized {
  // region GlobalError
  static readonly UNEXCEPTED_ERROR = $localize`Unknown error`;
  static readonly BAD_REQUEST = $localize`Bad Request`;
  static readonly MALFORMED_JSON = $localize`Malformed JSON`;
  static readonly VALIDATION_ERROR = $localize`Validation error`;
  static readonly ACCESS_DENIED = $localize`Access denied`;
  static readonly UNSUPPORTED = $localize`An unsupported operation has been attempted`;

  // region DbError
  static readonly ENTITY_NOT_FOUND = $localize`Entity not found`;
  static readonly ENTITY_ALREADY_EXIST = $localize`Entity already exist`;

  // region UsersError
  static readonly USER_NOT_FOUND = $localize`User not found`;
  static readonly USER_ALREADY_EXIST = $localize`User already exist`;
  static readonly ROLE_NOT_FOUND = $localize`Role not found`;
  static readonly ROLE_ALREADY_EXIST = $localize`Role already exist`;

  static readonly TECHNICAL_ERROR = $localize`Technical error`;
  static readonly REFERENTIAL_NOT_FOUND = $localize`Referential not found`;
  static readonly MEDIA_NOT_DOWNLOADABLE = $localize`Media not downloadable`;
  static readonly FORMAT_NOT_FOUND = $localize`Format not found`;
  static readonly FORMAT_NOT_EXISTS = $localize`Format not exists`;
  static readonly ORIGINAL_FORMAT_NOT_FOUND = $localize`Original format not found`;
  static readonly MEDIA_WRONG_FORMAT = $localize`Media wrong format`;
  static readonly MEDIA_MIME_TYPE_UNSUPPORTED = $localize`Media mime type unsupported`;
  static readonly MEDIA_WRONG_RESOLUTION = $localize`Media wrong resolution`;
  static readonly MISSING_MANDATORY_PARAMETER = $localize`Missing mandatory parameter`;
  static readonly UNABLE_TO_SAVE_FILE = $localize`Unable to save file`;
  static readonly UNABLE_TO_READ_FILE = $localize`Unable to read file`;
  static readonly UNABLE_TO_DELETE_FILE = $localize`Unable to delete file`;
  static readonly UNABLE_TO_CONVERT_FILE = $localize`Unable to convert file`;
  static readonly UNABLE_TO_REFRAME_PHOTO = $localize`Unable to reframe photo`;
  static readonly UNABLE_TO_EXTRACT_THUMBNAIL = $localize`Unable to extract thumbnail`;
  static readonly MALFORMED_URL = $localize`Malformed URL`;
}
